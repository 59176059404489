.aboutContent {
  /* display: -webkit-flex; */
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: auto;
  margin-top: 10px;
}

.aboutInfo {
  margin: 15px;
  width: 850px;
}

.aboutPicture {
  margin: 20px;
  padding-top: 20px;

  /* justify-content: flex-end; */
}

.aboutPicture img {
  max-width: 400px;
}
