@import './normalize.css';

/* GLOBALS */
html {
  min-height: 100%;
  height: 100% !important;
  min-width: 320px;
  color: #222;
  /* font-family: 'Nanum Myeongjo', serif; */
  /* font-family: 'Noto Serif KR', sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 300;
}
body,
#root,
#app,
#app > div {
  min-height: 100%;
  height: 100% !important;
}
::-moz-selection {
  background: #b3d4fc;
}
::selection {
  background: #b3d4fc;
}
#core-notifications > * {
  display: block;
  margin: 0;
  padding: 16px;
  background: #ffa;
  text-align: center;
  line-height: 2;
}

h1 {
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.25em;
  text-decoration: none;
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

ul {
  list-style: none;
}

a {
  color: #333;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

@media screen and (min-width: 450px) {
  h1 {
    font-size: 40px;
  }
}
