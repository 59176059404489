.header-title {
  padding: 10px 0;
}

.headerContent {
  padding: 0 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
}

.left li:nth-child(1) {
  margin-right: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (min-width: 600px) {
  .headerContent {
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
