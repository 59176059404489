.album-container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
/* .wrapper {
  display: grid;
  justify-content: center;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 25px 25px;
  grid-auto-flow: row dense;
  padding: 10px;
} */
.contentWrapper {
  justify-content: center;
  display: flex;
  flex: 1;
  /* background-color: red; */
}
.wrapContainer {
  width: 1660px;
}
.wrapper {
  margin: 0 0 20px 0;
  display: grid;
  grid-gap: 10px;
  justify-content: center;
  align-content: end;
}
.wrapper-photo {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.wrapper-painting {
  display: flex;
  justify-content: center;
  align-self: center;
}
.photoDescriptionContainer {
  flex-direction: column;
  display: flex;
  max-width: 600px;
  flex: 1;
  /* padding-left: 25px; */
}
.photoDescript {
  font-size: 16px;
  padding: 10px;
  text-align: justify;
}
.photoDescriptHeader {
  font-size: 20px;
  padding: 10px;
}
.content-painting {
  max-width: 960px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
.content-photo {
  max-width: 600px;
  flex: 1;

  justify-content: center;
  grid-gap: 10px;

  grid-template-columns: repeat(12, 1fr);

  padding: 10px;
}
.paintinfo {
  font-size: 12px;
}
.paintinfoHeader {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 15px;
}
.paintinfocontainer {
  margin-left: 12px;
}
@media (max-width: 450px) {
  .paintinfoHeader {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 10px;

    margin-right: 5px;
  }
  .paintinfocontainer {
  }
}

.photoInfo {
  margin-bottom: 30px;
}

.infoBottomContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  cursor: pointer;
  z-index: 9999;
}
.prc {
  font-size: 11px;
  color: grey;
}
.album-image {
  height: 300px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.albumItemP {
  /* width: 300px; */
  /* display: flex; */
  /* flex: 1; */
  /* max-height: 500px; */
}
.albumItemPainting {
  width: 300px;
  justify-content: end;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding: 30px; */
  /* background-color: yellowgreen; */
  /* padding-top: 30px; */
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
}
.imageItem {
  cursor: pointer;
  /* margin-top: 100px; */
}
.imageLandscapeItem {
  cursor: pointer;
  margin-top: 150px;
}
.paints {
  display: flex;
  flex: 1;
  max-width: 960px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
@media (min-width: 600px) {
  .albumItemPainting {
    max-width: 320px;
    justify-content: end;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 30px;
  }
  .wrapper-painting {
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .content-painting {
    flex-direction: column;
    /* display: flex; */
    /* flex: 1; */
    /* flex-wrap: wrap; */
    /* justify-content: center;
    align-items: center;
    align-content: center; */
  }
}
.loading {
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (min-width: 450px) {
  .albumItem.item1 {
    grid-column: span 12;
    grid-row: span 9;
  }
  .albumItem.item {
    grid-column: span 3;
    grid-row: span 5;
  }
  .albumItem.item2 {
    grid-column: span 6;
    grid-row: span 10;
  }
  .albumItem.item3 {
    grid-column: span 12;
    grid-row: span 20;
  }
  .albumItem.item4 {
    grid-column: span 4;
    grid-row: span 8;
  }
}
@media (max-width: 450px) {
  .wrapper-photo {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    background-color: 'lightblue';
  }
  .photoDescriptionContainer {
    flex-direction: column;
    display: flex;
    max-width: 400px;
    flex: 1;
    padding-left: 10px;
  }
}

/* @media (max-width: 450px) {
  .wrapper.album .item1 {
    grid-column: 1 / 13;
    grid-row: span 4;
  }
  .wrapper.album .item2 {
    grid-column: 1 / 13;
    grid-row: span 9;
  }
  .wrapper.album .item {
    grid-column: span 6;
    grid-row: span 4;
  }
} */
@media (min-width: 350px) {
  .wrapper.album .item1 {
    grid-column: 1 / 13;
    grid-row: span 7;
  }
  .wrapper.album .item2 {
    grid-column: 1 / 13;
    grid-row: span 14;
  }
  .wrapper.album .item {
    grid-column: span 6;
    grid-row: span 7;
  }
}
@media (min-width: 370px) {
  .wrapper.album .item1 {
    grid-column: 1 / 13;
    grid-row: span 8;
  }
  .wrapper.album .item2 {
    grid-column: 1 / 13;
    grid-row: span 16;
  }
  .wrapper.album .item {
    grid-column: span 6;
    grid-row: span 8;
  }
}
@media (min-width: 410px) {
  .wrapper.album .item1 {
    grid-column: 1 / 13;
    grid-row: span 9;
  }
  .wrapper.album .item2 {
    grid-column: 1 / 13;
    grid-row: span 18;
  }
  .wrapper.album .item {
    grid-column: span 6;
    grid-row: span 9;
  }
}
@media (min-width: 550px) {
  .wrapper.album .item1 {
    grid-column: span 8;
    grid-row: span 8;
  }
  .wrapper.album .item2 {
    grid-column: span 4;
    grid-row: span 8;
  }
  .wrapper.album .item {
    grid-column: span 4;
    grid-row: span 8;
  }
}
@media (min-width: 600px) {
  .wrapper.album .item1 {
    grid-column: span 8;
    grid-row: span 9;
  }
  .wrapper.album .item2 {
    grid-column: span 4;
    grid-row: span 9;
  }
  .wrapper.album .item {
    grid-column: span 4;
    grid-row: span 9;
  }
}
@media (min-width: 660px) {
  .wrapper.album .item1 {
    grid-column: span 8;
    grid-row: span 10;
  }
  .wrapper.album .item2 {
    grid-column: span 4;
    grid-row: span 10;
  }
  .wrapper.album .item {
    grid-column: span 4;
    grid-row: span 10;
  }
}
@media (min-width: 700px) {
  .wrapper.album .item1 {
    grid-column: span 8;
    grid-row: span 11;
  }
  .wrapper.album .item2 {
    grid-column: span 4;
    grid-row: span 11;
  }
  .wrapper.album .item {
    grid-column: span 4;
    grid-row: span 11;
  }
}
@media (min-width: 760px) {
  .wrapper.album .item1 {
    grid-column: span 8;
    grid-row: span 13;
  }
  .wrapper.album .item {
    grid-column: span 4;
    grid-row: span 13;
  }
  .wrapper.album .item2 {
    grid-column: span 4;
    grid-row: span 13;
  }
}
@media (min-width: 1000px) {
  .wrapper.album .item1 {
    grid-column: span 8;
    grid-row: span 15;
  }
  .wrapper.album .item {
    grid-column: span 4;
    grid-row: span 15;
  }
  .wrapper.album .item2 {
    grid-column: span 8;
    grid-row: span 30;
  }
}

@media (min-width: 1250px) {
  .wrapper.album .item1 {
    grid-column: span 6;
    grid-row: span 16;
  }

  .wrapper.album .item2 {
    grid-column: span 6;
    grid-row: span 32;
  }
  .wrapper.album .item {
    grid-column: span 3;
    grid-row: span 16;
  }
}

@media (min-width: 1650px) {
  .wrapper.album .item1 {
    grid-column: span 4;
    grid-row: span 13;
  }
  .wrapper.album .item2 {
    grid-column: span 4;
    grid-row: span 26;
  }
  .wrapper.album .item {
    grid-column: span 2;
    grid-row: span 13;
  }
}
