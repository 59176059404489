.aboutContent {
  /* display: -webkit-flex; */
  /* justify-content: center; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: auto;
  margin-top: 10px;
  align-items: flex-start;
}

.aboutInfo {
  margin: 15px;
}

.aboutScreenPicture {
  /* margin: 20px; */
  padding-top: 15px;
  justify-content: flex-start;
}

.aboutPicture img {
  max-width: 500px;
}
.containerAbout {
  display: flex;
  flex: 1;
}
.AboutText {
  /* margin-right: 50px; */

  margin-left: 20px;
  width: 420px;
  text-align: justify;
  font-size: 14px;
  letter-spacing: 1px;
}
.containerLink a {
  /* background-color: red; */
  text-decoration: underline;
  font-size: 18px;
}

.imageAbout {
  /* margin-left: 50px; */
  width: 410px;
}

@media (max-width: 450px) {
  .aboutContent {
    display: flex;
    flex: 1;
    width: 400px;
  }
  .AboutText {
    text-align: justify;
    margin-right: 20px;
    margin-left: 20px;
  }
  .imageAbout {
    /* margin-left: 50px; */
    padding-right: 10px;
    padding-left: 10px;
    width: 410px;
  }
}
