.wrapper {
  display: grid;
  justify-content: center;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 25px 25px;
  grid-auto-flow: row dense;
  padding: 10px;
}
.photography {
  display: flex;
  flex: 1;
  width: 300px;
  flex-direction: column;
}
.labelPhoto {
  margin-top: 10px;
  font-size: 16px;
}
.infoWrap {
  opacity: 0;
  transform: translateY(-100%);
  height: 100%;
  transition: opacity 0.3s;
  cursor: pointer;
  justify-content: space-between;
}

.infoWrap:hover {
  opacity: 0.3;
  background: rgba(255, 255, 255, 0.5);
}

.infoLink {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 95%;
  width: 95%;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  text-shadow: 0 0 100px white;
  text-transform: uppercase;
}

.loading {
  display: flex;
  position: relative;
  justify-content: center;
}
.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item8 img {
  width: 150%;
  height: 150%;
  object-fit: cover;
  margin-top: 60px;
  margin-left: -120px;
}
.item7 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 60px;
  /* margin-left: -120px; */
}
@media (max-width: 450px) {
  .item8 img {
    width: 100%;
    height: 100%;
    margin-left: 1px;
    margin-right: 1px;
    object-fit: cover;
    background-color: white;
    margin-top: 15vh;
    padding: 10px;
  }
  .item7 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 15vh;
    /* margin-left: -120px; */
  }
}

.item2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 300px) {
  .item1 {
    grid-column: 1 / 13;
    grid-row: span 6;
  }
  .item2 {
    grid-column: 1 / 13;
    grid-row: span 13;
  }
  .item {
    grid-column: span 6;
    grid-row: span 7;
  }
}
@media (min-width: 350px) {
  .item1 {
    grid-column: 1 / 13;
    grid-row: span 7;
  }
  .item2 {
    grid-column: 1 / 13;
    grid-row: span 15;
  }
  .item {
    grid-column: span 6;
    grid-row: span 8;
  }
}
@media (min-width: 380px) {
  .item1 {
    grid-column: 1 / 13;
    grid-row: span 8;
  }
  .item2 {
    grid-column: 1 / 13;
    grid-row: span 18;
  }
  .item {
    grid-column: span 6;
    grid-row: span 9;
  }
}
@media (min-width: 565px) {
  .item1 {
    grid-column: span 8;
    grid-row: span 8;
  }
  .item2 {
    grid-column: span 8;
    grid-row: span 16;
  }
  .item {
    grid-column: span 4;
    grid-row: span 8;
  }
}
@media (min-width: 630px) {
  .item1 {
    grid-column: span 8;
    grid-row: span 9;
  }
  .item {
    grid-column: span 4;
    grid-row: span 9;
  }
  .item2 {
    grid-column: span 8;
    grid-row: span 18;
  }
}
@media (min-width: 720px) {
  .item1 {
    grid-column: span 8;
    grid-row: span 11;
  }

  .item2 {
    grid-column: span 8;
    grid-row: span 22;
  }
  .item {
    grid-column: span 4;
    grid-row: span 11;
  }
}
@media (min-width: 790px) {
  .item1 {
    grid-column: span 6;
    grid-row: span 9;
  }

  .item2 {
    grid-column: span 3;
    grid-row: span 9;
  }
  .item {
    grid-column: span 3;
    grid-row: span 9;
  }
}
@media (min-width: 1000px) {
  .item1 {
    grid-column: span 6;
    grid-row: span 11;
  }

  .item2 {
    grid-column: span 6;
    grid-row: span 22;
  }
  .item {
    grid-column: span 3;
    grid-row: span 11;
  }
}
@media (min-width: 1200px) {
  .item1 {
    grid-column: span 6;
    grid-row: span 14;
  }

  .item2 {
    grid-column: span 6;
    grid-row: span 28;
  }
  .item {
    grid-column: span 3;
    grid-row: span 14;
  }
}
@media (min-width: 1400px) {
  .item1 {
    grid-column: span 6;
    grid-row: span 15;
  }

  .item2 {
    grid-column: span 6;
    grid-row: span 30;
  }
  .item {
    grid-column: span 3;
    grid-row: span 15;
  }
}
@media (min-width: 1650px) {
  .item1 {
    grid-column: span 4;
    grid-row: span 13;
  }

  .item2 {
    grid-column: span 4;
    grid-row: span 26;
  }
  .item {
    grid-column: span 2;
    grid-row: span 13;
  }
}

body {
  max-width: 1900px;
}

.wrapper {
  margin: 0 0 20px 0;
  /* display: grid; */
  grid-gap: 10px;
  justify-content: center;
  align-content: end;
}
.wrapper-pho {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.itemPhotoWrapper {
  padding: 10px;
}
.itemPhotoC img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemPhotoC {
  max-width: 300px;
}
.paintinfoHeaderC {
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  /* background-color: brown; */
}
.rowPriceC {
  flex: 1;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-bottom: 10px;
  margin-top: 20px;
  /* background-color: cyan; */
}

@media (max-width: 450px) {
  .itemPhotoC img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .itemPhotoC {
    width: 300px;
  }
  .paintinfoHeaderC {
    flex-direction: row;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 20px; */
    /* background-color: orange; */
  }
  .rowPriceC {
    flex: 1;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    margin-bottom: 10px;
    /* background-color: cyan; */
  }
}

.box {
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  padding: 2px;
  font-size: 150%;
}

.textPreview {
  -moz-animation: cssAnimation 1s ease-in 2s forwards;
  -webkit-animation: cssAnimation 1s ease-in 2s forwards;
  -o-animation: cssAnimation 1s ease-in 2s forwards;
  animation: cssAnimation 1s ease-in 2s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  font-size: 55px;
  padding: 15px;
  color: white;
  z-index: 100;
}

.fullPreview {
  -moz-animation: cssAnimation 1s ease-in 2s forwards;
  -webkit-animation: cssAnimation 1s ease-in 2s forwards;
  -o-animation: cssAnimation 1s ease-in 2s forwards;
  animation: cssAnimation 1s ease-in 2s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: white;
  display: grid;
  padding-right: 10px;
  z-index: 99;
}

.fullPreview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
