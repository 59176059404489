.link {
  height: 500px;
  width: 340px;
  margin: 5px;
  margin-bottom: 5px;
  /* box-shadow: 1px 1px 10px black; */
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 85;
}

.hover {
  display: flex;
  height: 100%;
  font-size: 15px;
  justify-content: flex-end;
  align-items: flex-end;
  color: black;
}

.albumName  {
display: block;
height: 500px;
width: 340px;
align-items: center;
color: black;
justify-content: flex-start;
background: linear-gradient(180deg, transparent 45%, white,transparent 170%);
position: relative;
text-align: center;
opacity: 0.9;
z-index: 5;

}

.albumBlur {
font-size: 25px;
position: absolute;
bottom: 5%;
right: 5%;
z-index: 6;
}

.link:hover{
/* transform: scale(1.009); */
opacity: 0.8;
z-index: 99;
.no-touch &:hover,
	.touch &:active {

	}
}


.container {
display:  flex;
/* flex-direction: row; */
justify-content: center ;
min-width:  300px;
align-items: center;
flex-wrap: wrap;
/* padding: 20px; */
z-index: 98;
position: relative;
}
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.column {
  flex: 1;
  padding: 0;
}

.column img {
  width: 100%;
  margin-top: 0;
  vertical-align: middle;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}
