.navbar {
  overflow: hidden;
  flex-direction: row;
  margin-left: 0px;
  /* font-family: 'Noto Serif KR', sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  z-index: 1;
  /* font-size: 20px; */
}

.navHorizontal {
  display: none;
}

.active-page {
  /* color: #d689d3!important; */
  background-color: #e1e1e1;
}

.dropdown {
  float: left;
  overflow: hidden;
  opacity: 0.9;
  z-index: 100;
  margin-right: 15px;
}

.dropdown .dropbtn {
  font-size: 20px;
  border: none;
  outline: none;
  color: #2c2c2c;
  background-color: inherit;
  font-family: inherit;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: white;
  cursor: pointer;
}
.imageButtonContainer {
  display: flex;
  width: 38px;
  height: 38px;
  z-index: 100;
  align-items: center;
  justify-content: center;
}
.menuButton {
  position: fixed;
  bottom: 60px;
  right: 25px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  z-index: 100;

  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
.imageClose {
  height: 40px;
  width: 40px;
}
.imageCloseButton {
  width: 25px;
  height: 25px;
}
.closeButton {
  right: 0;
  padding: 10px;
  position: absolute;
  display: flex;
}
.menuButton:hover {
  opacity: 0.8;
  cursor: pointer;
}
.dropdown-content {
  max-height: 0px;
  position: fixed;
  flex-direction: column-reverse;

  width: 100vw;
  overflow: hidden;
  transition: all 0.5s;

  z-index: 100;

  right: 0;
  bottom: 0;
  background-color: #f9f9f9;

  z-index: 100;
}
@media (min-width: 600px) {
  .dropdown-content {
    min-width: 100%;
    left: 0px;
  }
  .navbar {
    /* max-width: 350px; */
    display: flex;
    flex-wrap: wrap;
  }
  .navbar a {
    font-size: 15px;
    color: #2c2c2c;
    margin-left: 0px;
    padding: 5px 15px;
    text-decoration: none;
  }
  .menuButton {
    display: none;
  }
  .navHorizontal {
    display: flex;
  }
}

.dropdown-content a {
  margin: 10px;
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  text-align: left;
  position: static;
}

.dropdown-content a:hover {
  background-color: #dedede;
  transition: 0.5s;
}

.navbar a:hover {
  color: #707070;
}

.dropdown:hover .dropdown-content {
  /* display: block; */
  max-height: 1000px;
  background-color: #c9c9c9;
}
