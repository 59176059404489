.containerPhoto {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}
.popupWrap {
  display: block;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.popupWrap img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.popupWrapPhoto {
  display: block;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.popupWrapPhoto img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 7%;
  padding-right: 7%;
  max-width: 98%;
  max-height: 98%;
  object-fit: contain;
}
.buttonPrevPhoto {
  background: linear-gradient(270deg, transparent 50%, white, transparent 50%);
  /* background-color: #555555; */
  border: 0;
  padding: 0;
  outline: none;
  color: black;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  cursor: pointer;
  opacity: 0.6;
  position: fixed;
  left: 1%;
  bottom: 2%;
  height: 100%;
  width: 45%;
  z-index: 200;
}
.buttonPrev {
  background: linear-gradient(270deg, transparent 50%, white, transparent 50%);
  /* background-color: #555555; */
  border: 0;
  padding: 0;
  outline: none;
  color: black;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  cursor: pointer;
  opacity: 0.6;
  position: fixed;
  left: 1%;
  bottom: 2%;
  height: 100%;
  width: 45%;
  z-index: 200;
}
.buttonPrevPhoto span {
  position: fixed;
  left: 1%;
}
.buttonPrev span {
  position: fixed;
  left: 1%;
  bottom: 15%;
}
.buttonNextPhoto {
  background: linear-gradient(270deg, transparent 50%, white, transparent 50%);
  /* background-color: #555555; */
  border: 0;
  padding: 0;
  outline: none;
  color: black;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  cursor: pointer;
  opacity: 0.6;
  position: fixed;
  right: 1%;
  bottom: 2%;
  height: 100%;
  width: 45%;
  z-index: 200;
}
.buttonNextPhoto span {
  position: fixed;
  right: 1%;
}
.buttonNext {
  background: linear-gradient(270deg, transparent 50%, white, transparent 50%);
  /* background-color: #555555; */
  border: 0;
  padding: 0;
  outline: none;
  color: black;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  cursor: pointer;
  opacity: 0.6;
  position: fixed;
  right: 1%;
  bottom: 2%;
  height: 100%;
  width: 45%;
  z-index: 200;
}
.buttonNext span {
  position: fixed;
  right: 1%;
  bottom: 15%;
}
.buttonClose {
  position: fixed;
  top: 1%;
  right: 1%;
  opacity: 0.6;

  color: black;
  background: linear-gradient(90deg, transparent 50%, white, transparent 50%);
  padding: 19px 19px;
  border: hidden;
  cursor: pointer;

  z-index: 200;
}
.photoInf {
  font-size: 16px;
  padding-top: 20px;
}

.navigation {
  /* -webkit-animation: seconds 1.0s forwards;
   -webkit-animation-iteration-count: 1;
   -webkit-animation-delay: 2s;
   animation: seconds 1.0s forwards;
   animation-iteration-count: 1;
   animation-delay: 2s;
   position: fixed;
   top: 0;
   right: 0;
   background-color: white;
   padding-top: 49px;
   padding-right: 5px; */
}
@-webkit-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
