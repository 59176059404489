.splashContainer {
  display: flex;
  flex: 1;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 120px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.eventContainer {
  margin-bottom: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.eventHeader {
  font-size: 64px;
  flex-wrap: wrap;
  font-weight: 400;
  margin-bottom: 20px;
  width: 250px;

  /* line-height: 0.2; */
}
.eventHeader2 {
  margin-top: 10px;
  font-size: 32px;
}
.eventText {
  font-size: 22px;
  margin-top: 20px;

  flex-wrap: wrap;
  display: flex;
}
@media (max-width: 600px) {
  .eventHeader {
    font-size: 38px;
    width: 280px;
    /* flex-wrap: wrap; */
    text-align: center;
  }
  .eventText {
    font-size: 18px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .splashContainer {
    width: 100vw;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes motto {
  0% {
    opacity: 1;

    transform: translate3d(500%, -400%, 0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: none;
    transform: scale(0.5);
  }
}
@keyframes motto1 {
  0% {
    opacity: 1;
    transform: translate3d(-700%, 200%, 0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: none;
    transform: scale(0.5);
  }
}
@keyframes motto2 {
  0% {
    opacity: 1;
    transform: translate3d(-550%, 350%, 0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: none;
    transform: scale(0.5);
  }
}
@keyframes motto3 {
  0% {
    opacity: 1;
    transform: translate3d(-200%, 450%, 0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: none;
    transform: scale(0.5);
  }
}
@keyframes motto4 {
  0% {
    opacity: 1;
    transform: translate3d(-10%, 650%, 0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: none;
    transform: scale(0.5);
  }
}
@keyframes motto5 {
  0% {
    opacity: 1;
    transform: translate3d(150%, 450%, 0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: none;
    transform: scale(0.3);
  }
}
@keyframes motto6 {
  0% {
    opacity: 1;
    transform: translate3d(250%, 350%, 0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: none;
    transform: scale(0.3);
  }
}
@keyframes motto7 {
  0% {
    opacity: 1;
    transform: translate3d(550%, 550%, 0);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: none;
    transform: scale(0.3);
  }
}
@keyframes fadeIn2 {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn3 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.85);
  }
  15% {
    transform: scale(1);
  }
  35% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
  65% {
    transform: scale(0.85);
  }
  75% {
    transform: scale(1);
  }
  90% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse2 {
  65% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
.bg1 {
  height: 250px;
  /* position: absolute; */
  /* top: 0;*/
  margin-bottom: 150px;
  animation: pulse 8s, fadeIn3 5s;
  /* padding-bottom: 80px; */
  transition: 0.3s;
}
.bgText {
  /* height: 250px; */
  /* position: absolute; */
  /* top: 0;*/
  /* margin-bottom: 150px; */
  animation: pulse 18s, fadeIn3 5s;
  /* padding-bottom: 80px; */
  transition: 0.3s;
}
.bg1:hover {
  animation: pulse2 2s, fadeIn3 3s;
}
.bg2 {
  position: absolute;
  height: 150px;
  top: 280px;
  bottom: 0px;

  /* padding-left: 500px; */
  /* animation: fadeIn2 5s; */
  animation-name: motto;
  animation-duration: 8s;
  animation-fill-mode: forwards;
}
.bg3 {
  position: absolute;
  height: 350px;
  bottom: 100px;
  animation: fadeIn2 18s, pulse2 5s;
}
.bg4 {
  height: 230px;
  position: absolute;
  animation: fadeIn2 5s;
  animation-name: motto1;
  animation-duration: 8s;
  animation-fill-mode: forwards;
}
.bg5 {
  height: 250px;
  position: absolute;
  animation: fadeIn2 5s;
  animation-name: motto2;
  animation-duration: 10s;
  animation-fill-mode: forwards;
}
.bg6 {
  height: 280px;
  position: absolute;
  animation: fadeIn2 5s;
  animation-name: motto3;
  animation-duration: 11s;
  animation-fill-mode: forwards;
}
.bg7 {
  height: 250px;
  position: absolute;
  animation: fadeIn2 5s;
  animation-name: motto4;
  animation-duration: 8s;
  animation-fill-mode: forwards;
}
.bg8 {
  height: 290px;
  position: absolute;
  animation: fadeIn2 5s;
  animation-name: motto5;
  animation-duration: 11s;
  animation-fill-mode: forwards;
}
.bg9 {
  height: 270px;
  position: absolute;
  animation: fadeIn2 5s;
  animation-name: motto6;
  animation-duration: 9s;
  animation-fill-mode: forwards;
}
.bg9 {
  height: 270px;
  position: absolute;
  animation: fadeIn2 5s;
  animation-name: motto7;
  animation-duration: 7s;
  animation-fill-mode: forwards;
}
@media (max-width: 450px) {
  .bg1 {
    height: 250px;
    animation: pulse 8s, fadeIn3 5s;
  }
  .bg2 {
    position: absolute;
    display: none;
    height: 100px;
    animation: fadeIn2 5s;
    animation-name: motto;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
  .bg3 {
    position: absolute;
    height: 150px;
    bottom: 100px;
    animation: fadeIn2 8s;
  }
  .bg8 {
    display: none;
  }
  .bg9 {
    display: none;
  }
}
